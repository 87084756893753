<template>
<v-autocomplete
  v-model="model"
  :label="$vuetify.lang.t(label)"
  :items="list"
  clearable
  hide-no-data
  hide-selected
  item-text="name"
  item-value="code"
  autocomplete="country"
  v-bind="$attrs"
></v-autocomplete>
</template>

<script>
import countryList from 'country-list';

export default {
  name: 'CountrySelect',

  props: {
    label: {
      type: String,
      default: '$vuetify.paymentForm.fields.country'
    },
    value: {},
  },

  data() {
    return {};
  },

  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },

    list() {
      return countryList.getData();
    },
  },
};
</script>
